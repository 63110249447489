// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // Environment
  currentEnviroment: 'dev',
  production: false,

  // Otros
  appVersion: 'v8.0.25',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,

  // REST-API
  apiUrlMessageBirds: 'https://mbdev.pervolare.net',
  apiUrlTasOld: 'https://admin.traveler-assistance.com',
  serviceBlogHost: 'https://api-blog.dev.pervolare.net',
  apiUrl: 'https://api-management-traveler.azure-api.net',
  trmUrl: 'https://exchangerates-aws.pervolare.net/exchangeRate',
  apiUrlLandings: 'https://api-landings-tas.dev.pervolare.net',
  quoterServer: 'https://quoter-tas.pervolare.net/quoter-B2B/development',

  // Links
  appThemeName: 'Metronic',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/docs/changelog',

  // Suscription Keys
  apikeys: {
    general: '9661843830374df0b4cf7daf42cf6d44',
    payments: '45cce68b88af4e07804c981505f94bcb',
    keyAccess: 'YnVjOFF+WHVSN0hpQ2dJcU9WTmFqWEFuc3hjLTRBNFFvczRSaWJBdA=='
  },

  TASSource: 'https://new.traveler-assistance.com',
  ASNSource: 'https://dev.assistnetcard.com',
  SDVMSource: 'https://sdvm-qa.pervolare.net',
  mainPageUrl: 'https://new.traveler-assistance.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.